import React, { createContext, useContext, useState } from 'react'

// Add here global variables and their associated state functions 
// to be used throughout the project
export type GlobalVariables = {
  orderKey: string,
  setOrderKey: (o: string) => void,
  cosiumId: string,
  setCosiumId: (o: string) => void,
  siteDomainSuffix: string,
  setSiteDomainSuffix: (o: string) => void,
  isAftersalesOrder: boolean,
  setIsAftersalesOrder: (o: boolean) => void
}

// Insert both variable and function initializations
export const GlobalContext = createContext<GlobalVariables>({
  orderKey: '',
  setOrderKey: () => {},
  cosiumId: '',
  setCosiumId: () => {},
  siteDomainSuffix: '',
  setSiteDomainSuffix: () => {},
  isAftersalesOrder: false,
  setIsAftersalesOrder: () => {}
})

export const GlobalContextProvider: React.FC<React.ReactNode> = ({
  children
}) => {
  // Add the useState used for each variable
  const [orderKey, setOrderKey] = useState<string>('')
  const [cosiumId, setCosiumId] = useState<string>('')
  const [siteDomainSuffix, setSiteDomainSuffix] = useState<string>('')
  const [isAftersalesOrder, setIsAftersalesOrder] = useState<boolean>(false)

  // Inject them into the provider
  return (
    <GlobalContext.Provider value={{ orderKey, setOrderKey, cosiumId, setCosiumId, siteDomainSuffix, setSiteDomainSuffix, isAftersalesOrder, setIsAftersalesOrder }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => useContext(GlobalContext)

export default GlobalContext