import { useMutation, useQuery } from '@apollo/client'
import { useState, useContext } from 'react'
import AuthContext from 'store/auth-context'
import { ToastContext } from 'store/toast-context'
import { constants } from 'utils/constants'
import ResultsTable from 'components/UI/ResultsTable'
import {
  checkRetraitParLeClient,
  extractPhoneNumberFromContactInfo,
} from 'utils/functions'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Card from 'components/UI/Card'
import { stringsToJSXs } from 'utils/functions'
import { useTranslation } from 'react-i18next'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import { GET_ORDER_LISTING } from 'graphql/queries/getOrderListing'
import DOButton from 'components/Modal/DOButton'
import DOModal from 'components/Modal/DOModal'
import ModalOrderProducts from 'components/Modal/ModalOrderProducts'

interface Props {}

const RetraitParLeClient: React.FC<Props> = () => {
  const authCtx = useContext(AuthContext)
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()
  const [showProductsModal, setShowProductsModal] = useState(null)

  const [isDisabled, setIsDisabled] = useState(false)

  const storeId = parseInt(authCtx.user.storeId)

  const { loading, error, data } = useQuery(GET_ORDER_LISTING, {
    variables: {
      filter: `{ "$and": [{"deliveryStore__id": "${storeId}"}, {"workflowState":{"$notlike":"completed"}}]}`,
    },
  })

  const [createDOOrderEvent] = useMutation(CM_CREATE_DO_ORDER_EVENT, {
    refetchQueries: [
      // works only on success
      GET_ORDER_LISTING,
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      setIsDisabled(false)
      toast({
        title: 'Retrait validé pour :',
        message: `Commande n°${data.CMCreateDOOrderEvent.output.orderEvent.order.id}`,
      })
    },
    onError: () => {
      setIsDisabled(false)
    },
  })

  if (loading)
    return (
      <>
        <Card>
          <SkeletonBase count={6} />
        </Card>
      </>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const confirmDelivery = async (order: orderType) => {
    setIsDisabled(true)
    try {
      await createDOOrderEvent({
        variables: {
          orderId: +order.id,
          eventTypeKey:
            constants.eventTypeKeys
              .ORDER_EVENT_TYPE_PICKED_UP_BY_CUSTOMER_IN_STORE,
        },
      })
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }

  const jsonData = JSON.parse(JSON.stringify(data))

  jsonData.getOrderListing.edges.forEach((item: any) => {
    let order = item.node.order

    order.fullName =
      order.deliveryName !== null
        ? order.deliveryName[0].firstName + ' ' + order.deliveryName[0].lastName
        : '-'

    order.phone = extractPhoneNumberFromContactInfo(order.deliveryAddress)

    order.orderKey = order.key

    const arrivedAtStoreEvents = order.events.filter(
      (event: orderEvent) =>
        event.closureDate === null &&
        event.eventType.key ===
          constants.eventTypeKeys.ORDER_EVENT_TYPE_ARRIVED_AT_STORE
    )
    const arrivedAtStoreEvent =
      arrivedAtStoreEvents.length > 0 ? arrivedAtStoreEvents[0] : null
    order.orderArrivedAtStoreDate = arrivedAtStoreEvent
      ? new Intl.DateTimeFormat('fr', {
          dateStyle: 'short',
          timeStyle: 'medium',
        }).format(arrivedAtStoreEvent.creationDate * 1000)
      : '-'
      
      order.products = (
        <>
          <div>
            <DOButton
              className="btn-primary"
              text="Voir"
              handleClick={() => setShowProductsModal(order.id)}
            />
            <DOModal
              show={showProductsModal === order.id}
              customSize="modal-647"
              closeModal={() => setShowProductsModal(null)}
            >
              <ModalOrderProducts orderId={order.id} orderKey={order.key} fullName={order.fullName} phone={order.phone}/>
            </DOModal>
          </div>
        </>
      )

    order.deliveryToCustomer = (
      <button
        className="btn btn-primary"
        onClick={() => {
          if (
            window.confirm(
              `Êtes-vous sûr de vouloir fermer le statut de livraison magasin pour la commande du client ${order.fullName}?`
            )
          )
            confirmDelivery(order)
        }}
        disabled={isDisabled}
      >
        Valider retrait
      </button>
    )
  })

  return (
    <div>
      {data && (
        <Card>
          <ResultsTable
            queryType="Commandes à retirer par le client"
            items={jsonData.getOrderListing.edges.filter((item: any) => {
              return checkRetraitParLeClient(item.node.order)
            })}
            columns={stringsToJSXs(
              [
                'orderKey',
                'cosiumId',
                'fullName',
                'orderArrivedAtStoreDate',
                'mailsSentCount',
                'products',
                'deliveryToCustomer',
                'phone'
              ],
              t
            )}
          />
        </Card>
      )}
    </div>
  )
}

export default RetraitParLeClient
