import './VoucherDetails.css'
import DOModal from 'components/Modal/DOModal'
import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'
import { getFormattedDate, isVoucherOnTime } from 'utils/functions'
import { constants } from 'utils/constants'

interface Props {
  voucher: voucherType
  mutationFunction: any
  btnLabel: string
  confirmMessage: string
  toastTitle: string
  managesGenericVouchers?: boolean
  isVoucherAlreadyUsedByUser?: boolean
  onInputValueChange?: (value: string) => void
}

const VoucherDetails: React.FC<Props> = ({
  voucher,
  mutationFunction,
  btnLabel,
  confirmMessage,
  toastTitle,
  managesGenericVouchers,
  isVoucherAlreadyUsedByUser,
  onInputValueChange
}) => {
  
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (value: string) => {
    const regex = /^D\d{9}$/;
    return regex.test(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateInput(inputValue)) {
      setErrorMessage(
        'Le numéro saisi est incorrect. Le format d’un numéro de devis Cosium commence par la lettre D suivi de 9 chiffres.'
      );
      return;
    }
    setErrorMessage('');
    setShowConfirmMutateVoucherModal(true);
  };

  const isAvailable =
    voucher.voucherState === 'disponible' &&
    isVoucherOnTime(voucher.dateBegin, voucher.dateEnd)
  const isExpired =
    voucher.voucherState === 'disponible' &&
    !isVoucherOnTime(voucher.dateBegin, voucher.dateEnd)
  const isUsed = voucher.voucherState === 'utilise'
  const isCancelled = voucher.voucherState === 'annule'
  const isDisabled = voucher.voucherState === 'desactive'

  // Complex states (depending on voucherType generic)
  const userCanMutateVoucherRights =
    isAvailable &&
    (!voucher.isGeneric || managesGenericVouchers) &&
    !isVoucherAlreadyUsedByUser

  const userCanNotUpdateGenericVoucher =
    isAvailable && voucher.isGeneric && !managesGenericVouchers
  /** END Pre-calculating voucher display */

  const { t } = useTranslation()
  const { toast } = useContext(ToastContext)

  const [showConfirmMutateVoucherModal, setShowConfirmMutateVoucherModal] =
    useState(false)
  // State to define if available generic voucher should be mutable or not (default value defined according to isVoucherAlreadyUsedByUser)
  const [isGenericVoucherUsedByUser, setIsGenericVoucherUsedByUser] = useState(
    isAvailable &&
      voucher.isGeneric &&
      managesGenericVouchers &&
      isVoucherAlreadyUsedByUser
  )

  const submitMutateVoucher = async () => {
    try {
      await mutationFunction()

      toast({
        title: toastTitle,
        message: voucher.voucherCode,
      })
      // if we have mutated a generic voucher, it should not be mutable again (we only use a generic voucher once)
      if (voucher.isGeneric) setIsGenericVoucherUsedByUser(true)
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    } finally {
      setShowConfirmMutateVoucherModal(false)
    }
  }

  return (
    <>
      <div
        className={`ms-4 ${
          isAvailable && !isGenericVoucherUsedByUser
            ? 'valid-voucher'
            : 'invalid-voucher'
        }
        `}
      >
        Bon valable du {getFormattedDate(voucher.dateBegin)} au{' '}
        {getFormattedDate(voucher.dateEnd)}
        <br />
        Libellé client : {voucher.voucherTemplate.clientLabel}
        <br />
        Type :{' '}
        {voucher.voucherTemplate.voucherType
          ? t(`voucher.${voucher.voucherTemplate.voucherType}`)
          : ''}
        <br />
        Montant : {voucher.totalAmount?.value.toFixed(2)}{' '}
        {constants.currency.EURO} (minimum de commande :{' '}
        {voucher.voucherTemplate.minAmount?.value.toFixed(2)}{' '}
        {constants.currency.EURO})
        <br />
        {voucher.voucherTemplate.productType && (
          <div>Autorisé pour : {t(voucher.voucherTemplate.productType)}</div>
        )}
        Applicable sur les produits en solde ou promotion :{' '}
        {voucher.voucherTemplate.onDiscount ? 'OUI' : 'NON'}
        <br />
        Sur 1ère commande uniquement :{' '}
        {voucher.voucherTemplate.onlyFirstOrder ? 'OUI' : 'NON'}
        <br />
        Sites où est valable le bon :{' '}
        {voucher.voucherTemplate.domainSites?.join(', ')}
      </div>

      <div className="text-center">
        {userCanMutateVoucherRights && !isGenericVoucherUsedByUser && (
          <form onSubmit={handleSubmit} className="form-container">
            <label htmlFor="cosium-quote-number" className="form-label">
              Numéro de devis Cosium
            </label>
            <input
              type="text"
              id="cosium-quote-number"
              name="cosiumQuoteNumber"
              className="form-input"
              value={inputValue}
              onChange={(e) => 
                {
                  setInputValue(e.target.value)
                  if (onInputValueChange) {
                    onInputValueChange(e.target.value);
                  }
                }
              }
              placeholder="Entrez le numéro de devis"
              required
            />
            {errorMessage && <p className="form-error">{errorMessage}</p>}
            <Button type='submit' disabled={!inputValue.trim()} className="form-button">
              {btnLabel}
            </Button>
          </form>
        )}
        {isGenericVoucherUsedByUser && (
          <span className="invalid-voucher">
            Bon déjà utilisé par le client
          </span>
        )}
        {userCanNotUpdateGenericVoucher && (
          <span className="invalid-voucher">
            Un bon générique ne peut pas être désactivé
          </span>
        )}
        <p className="invalid-voucher">
          {isExpired && <span className="invalid-voucher">Bon périmé</span>}
          {isCancelled && <span className="invalid-voucher">Bon annulé</span>}
          {isDisabled && <span className="invalid-voucher">Bon désactivé</span>}
          {isUsed && (
            <span className="invalid-voucher">
              Bon utilisé le{' '}
              {getFormattedDate(voucher.voucherUsage[0]?.usageDate)}
            </span>
          )}
        </p>
      </div>

      {/* Confirm modale to ensure the user wants to update voucher */}
      <DOModal
        show={showConfirmMutateVoucherModal}
        children={
          <>
            <div className="d-flex flex-column">
              <span>{confirmMessage}</span>
              <span>
                {voucher.voucherCode} - {voucher.voucherTemplate.clientLabel} ?
              </span>
            </div>
          </>
        }
        confirmModal={submitMutateVoucher}
        closeModal={() => setShowConfirmMutateVoucherModal(false)}
      />
    </>
  )
}

export default VoucherDetails
