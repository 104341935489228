import { useLazyQuery } from '@apollo/client'
import React, { useState, useEffect, useCallback } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import GET_TOTAL_ORDERS_BY_ORDER_EVENT_TYPE_LISTING from 'graphql/queries/getTotalOrdersByOrderEventTypeListing'
import TreeMenu, { Item, ItemComponent } from 'react-simple-tree-menu'
import './GestionCommande.css'
import { useTranslation } from 'react-i18next'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import GestionCommandeResults from './GestionCommandeResults'
import GestionCommandeResultsNew from './GestionCommandeResultsNew'

interface Props {}
// /bo/relation_client/monitoring_commande in documents Pimcore
const GestionCommande: React.FC<Props> = () => {
  const { t } = useTranslation()

  const [openNodes, setOpenNodes] = useState<string[]>([])
  const [selectedEventTypeId, setSelectedEventTypeId] = useState<number>(0)
  const [monitoringData, setMonitoringData] = useState<any | null>(null)
  const [isOtherEvent, setIsOtherEvent] = useState<boolean>(false)

  let localStorageMonitoringData = localStorage.getItem('monitoring_data')

  const [getOrderMonitoringListing, { loading, error, data }] = useLazyQuery(
    GET_TOTAL_ORDERS_BY_ORDER_EVENT_TYPE_LISTING, {
      onCompleted: (data) => {
        localStorage.setItem('monitoring_data', JSON.stringify(data))
      }
    }
  )

  const fetchMonitoringListing = useCallback(async() => {
    await getOrderMonitoringListing({})
  }, [getOrderMonitoringListing])

  useEffect(() => {
    fetchMonitoringListing()
  }, [fetchMonitoringListing])

  useEffect(() => {
    setMonitoringData(!data && localStorageMonitoringData ? JSON.parse(localStorageMonitoringData) : data)
  }, [data, localStorageMonitoringData])

  if (loading && !monitoringData) { // Display skeleton only when there is nothing in local storage
    return (
      <div>
        <div className="col-md-2">
          <SkeletonBase count={30} />
        </div>
      </div>
    )
  }
  if (error || !monitoringData) {
    // Cas normalement impossible
    return (
      <Card>
        <p>Aucun statut trouvé</p>
      </Card>
    )
  }

  let treeData: any = {} // Contient l'arborescence de type { 'cle-categorie': { label: 'Nom catégorie', nodes: { 'cle-evenement': { label: 'Nom type' } } }}
  // avec 'cle-categorie' de type 'category-{{ID_DOOrderEventTypeCategory}}' (sauf pour "Autres" qui va contenir tous les types qui n'appartiennent à aucune catégorie)
  // avec 'cle-evenement' de type 'type-{{ID_DOOrderEventType}}'

  let initialOpenNodes: string[] = [] // Catégories ouvertes au chargement du composant (pour distinguer l'élément du state openNodes)
  let requiresReinitOpenNodes: boolean = true // Permet de définir les catégories ouvertes au premier chargement
  if (openNodes.length > 0) {
    // Si openNodes n'est pas vide, on ne va pas redéfinir les catégories ouvertes au chargement
    requiresReinitOpenNodes = false
    initialOpenNodes = openNodes
  }

  let othersNodeElements: any[] = [] // will keep the elements without category to add them to the list at the end
  for (const totalOrdersByOrderEventType of monitoringData
    .getTotalOrdersByOrderEventTypeListing.edges) {
    const node = totalOrdersByOrderEventType.node

    if (!node.orderEventTypeCategoryId) {
      // this element should be displayed in "Others" category
      othersNodeElements.push(totalOrdersByOrderEventType)
      continue
    }

    // Ajout de la catégorie dans les catégories ouvertes au premier chargement
    if (
      requiresReinitOpenNodes &&
      !initialOpenNodes.includes('category-' + node.orderEventTypeCategoryId)
    )
      initialOpenNodes.push('category-' + node.orderEventTypeCategoryId)

    // Adding category to the list if not known yet
    if (!('category-' + node.orderEventTypeCategoryId in treeData)) {
      treeData['category-' + node.orderEventTypeCategoryId] = {
        label: node.orderEventTypeCategoryLabel,
        nodes: {},
      }
    }
    // Adding type label to the category
    treeData['category-' + node.orderEventTypeCategoryId].nodes[
      'type-' + node.orderEventTypeId
    ] = {
      label: node.orderEventTypeLabel
    }
  }

  // Handling 'Others' element
  if (othersNodeElements.length > 0) {
    treeData['category-others'] = {
      label: t('others'),
      nodes: {},
    }

    for (const othersNodeElement of othersNodeElements) {
      const node = othersNodeElement.node
      treeData['category-others'].nodes['type-' + node.orderEventTypeId] = {
        label: node.orderEventTypeLabel,
      }
    }

    // If there is no category in database, we open node "Others" by default (just in case)
    if (initialOpenNodes.length === 0 && requiresReinitOpenNodes)
      initialOpenNodes.push('category-others')
  }

  if (requiresReinitOpenNodes) {
    // Si on a défini les catégories ouvertes au chargement, on met à jour notre state
    setOpenNodes(initialOpenNodes)
  }

  /**
   * When clicking on a label
   */
  const onClickNode = (props: Item) => {
    if (props.level === 1) {
      window.scrollTo(0, 0) // Just in case, to make sure that the user will notice that the results are displayed
      setSelectedEventTypeId(+props.key.split('/type-')[1]) // NB: props.key is like category-{{category_id}}/type-{{type_id}}
      setIsOtherEvent(props.parent==='category-others')//we need it is a category-others
      return
    }

    // Handling click on category
    if (Object.keys(treeData).length === 1) return // Handling specific case when there is only one category: we do nothing when clicking (else there is a bug and the category can not be open again)

    // Opening or closing category node if click on label
    if (props.isOpen && initialOpenNodes.includes(props.key))
      initialOpenNodes.splice(initialOpenNodes.indexOf(props.key), 1)
    else if (!props.isOpen && !initialOpenNodes.includes(props.key))
      initialOpenNodes.push(props.key)

    setOpenNodes(initialOpenNodes)
  }

  /**
   * Fonction exécutée lors du clic sur le + ou le - à gauche du label de la catégorie (selon si la catégorie est fermée ou ouverte)
   */
  const toggleElement = (props: any) => {
    // On déclenche le onClick pour que le fonctionnement soit le même que si on clique sur le label de la catégorie (cf. fonction onClickNode)
    props.onClick()
  }

  return (
    <div className="GestionCommande">
      {/* Menu des statuts : utilisation de react-simple-tree */}
      <div className="row">
        <div
          className="col-md-3"
          style={{ height: '80vh', overflowY: 'scroll' }} //  TODO à revoir (Matthieu ?) : ne pas mettre en dur la hauteur, mais il faut que la liste ait une scrollbar sinon le scroll infini du tableau de résultats ne marche pas
        >
          <TreeMenu
            data={treeData}
            initialOpenNodes={openNodes}
            onClickItem={onClickNode}
            hasSearch={false}
          >
            {({ search, items }) => (
              <div className="gestion-commande-event-type-list">
                {monitoringData && loading && ( // Display spinner only when some data is already present and while the query is running in the background
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="spinner"
                    variant="primary"
                  />
                )}
                <ul className="rstm-tree-item-group">
                  {items.map(({ item, key, ...props }) => (
                    <ItemComponent
                      key={key}
                      {...props}
                      toggleNode={() => toggleElement(props)}
                    />
                  ))}
                </ul>
              </div>
            )}
          </TreeMenu>
        </div>

        {/* Tableau des résultats */}
        <div className="col-md-9">
          {selectedEventTypeId !== 0 && (
            isOtherEvent ? //CQ with DOOrder Listing
            <GestionCommandeResults orderEventTypeId={selectedEventTypeId}/> 
            : //CQ with order_by_event_listing view
            <GestionCommandeResultsNew orderEventTypeId={selectedEventTypeId} /> 
          )}
          {selectedEventTypeId === 0 && <>Veuillez sélectionner un statut</>}
        </div>
      </div>
    </div>
  )
}

export default GestionCommande
