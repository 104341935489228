import React, { useEffect, useState } from 'react';
import Indicator from './Indicator';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useLazyQuery } from '@apollo/client';
import { GET_INDICATORS } from 'graphql/queries/getIndicators';

const idc = [
    {
        id: 0,
        key: 'totalOnlineProducts',
        label: 'Total produits en ligne',
        value: 0,
    },
    {
        id: 1,
        key: 'tryAtHome',
        label: 'Essayables à domicile',
        value: 0,
    },
    {
        id: 2,
        key: 'outOfStock',
        label: 'Rupture de stock',
        value: 0,
    },
    {
        id: 3,
        key: 'outOfStockTryAtHome',
        label: 'Rupture de stock essdom',
        value: 0,
    },
    {
        id: 4,
        key: 'tryOnline',
        label: 'Essayables en ligne',
        value: 0,
    },
];

const Indicators: React.FC = () => {
    const [indicators, setIndicators] = useState(idc);

    const [fetchIndicators, { loading: getIndicatorsLoading, data: getIndicatorsData }] = useLazyQuery(GET_INDICATORS);

    useEffect(() => {
        if (getIndicatorsData) {
            setIndicators((prevIndicators) => {
                return prevIndicators.map((item) => {
                    const key = item.key;
                    const value = getIndicatorsData?.getIndicators[key];
                    return { ...item, value: value ?? -1 };
                });
            });
        }
    }, [getIndicatorsData]);

    return (
        <Container className="gap-3" style={{ maxWidth: '960px' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="fs-3">Indicateurs produits</h2>
                <button
                    className="btn btn-primary"
                    onClick={() => fetchIndicators()}
                    disabled={getIndicatorsLoading}
                    title="Actualiser"
                >
                    <span className={`text-white ${getIndicatorsLoading ? 'spin' : ''}`}>🗘</span>
                </button>
            </div>
            <Row>
                {indicators.map((indicator) => (
                    <Col key={indicator.id}>
                        <Indicator
                            label={indicator.label}
                            value={indicator.value}
                            loading={getIndicatorsLoading}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Indicators;
