import { useMutation, useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import ResultsTable from 'components/UI/ResultsTable'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  stringsToJSXs,
  getFormattedDate,
} from 'utils/functions'
import UPDATE_DO_FRAME_VISIBILITY from 'graphql/mutations/updateDOFrameVisibility'
import GET_NEW_DO_FRAME_LISTING from 'graphql/queries/getNewDOFrameListing'
import UPDATE_DO_FRAME_WITH_VTO_IMG from 'graphql/mutations/updateDOFrameWithVtoImg'
import AuthContext from 'store/auth-context'
import { PlusIcon } from '@heroicons/react/outline'

const MonitoringProduits: React.FC = () => {

  const { t } = useTranslation()

  const [offset, setOffset] = useState(0)
  const limit = 30
  const authCtx = useContext(AuthContext)
  const [resultData, setResultData] = useState<any[]>([])
  const [totalFrames, setTotalFrames] = useState<number>(0)
  //state for list of checkbox checked
  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  //state for list of items checkable
  const [checkableList, setCheckableList] = useState<any[]>([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showAssociationModal, setShowAssociationModal] = useState(false)
  const [associationModalMessage, setAssociationModalMessage] = useState("")
  const [updateFrameId, setUpdateFrameId] = useState(0)

  //frame mutation to change visibility
  const [updateFrameVisibility, { loading: updateFrameMutationLoading }] =
    useMutation(UPDATE_DO_FRAME_VISIBILITY, {
      onCompleted: (data) => {
        if(data.updateDOFrameVisibility?.success){
          //close modal
          setShowConfirmModal(false)
          //remove the frame from resultData
          setResultData((prevData) =>
            prevData.filter((item) => +item.id !== updateFrameId)
          )
        }
      }
    }
  )
  //custom query : new frame listing (new frame or frame without img)
  const { error, loading, fetchMore } = useQuery(
    GET_NEW_DO_FRAME_LISTING, {
      variables: { offset, limit },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        handleResultDataUpdate(data)
      }
    }, 
    )
  //mutation to associate vto img with frame
  const [associateVtoImgMutation, {loading: associateVtoImgLoading }] =
    useMutation(UPDATE_DO_FRAME_WITH_VTO_IMG, {
      onCompleted: (data) => {
        const success = data.updateDOFrameWithVtoImg.success
        const countUpdatedFrames = data.updateDOFrameWithVtoImg.output.count
        //if at least one frame is updated 
        if (countUpdatedFrames) {
          // update resultData
          updateResultDataAfterMutation(data.updateDOFrameWithVtoImg.output.frames)
        }
        // set association modal message
        if(success){
          if(countUpdatedFrames) 
            setAssociationModalMessage(`Les photos de ${countUpdatedFrames} produits ont été associés avec succès.`)
          else 
            setAssociationModalMessage("Il n'y a pas de photos à associer pour les produits sélectionnés.")
        }else{
          setAssociationModalMessage(`Le traitement n’a pu aboutir à cause de l’erreur suivante : ${data.updateDOFrameWithVtoImg.output.message}`
          +(countUpdatedFrames?". Mais les photos de "+countUpdatedFrames +" produits ont été associés avec succès.":""))
        }
      },
  })
  //function called after associateVtoImgMutation
  const updateResultDataAfterMutation = (updatedFrames:any) => {
    let newcheckableList = [...checkableList]
    const updatedResultData = resultData.map((resultItem) => {
      //check if updatedFrames has a frame with same id in resultData
      const correspondingFrame = updatedFrames.find((frame:any) => frame.id === resultItem.node.id)
      if (correspondingFrame) {
        //set new checkableList
        newcheckableList = newcheckableList.filter((item) => item.gtin !== correspondingFrame.gtin)
        return {
          ...resultItem,
          node: {
            ...resultItem.node,
            checkboxVisible: false, // hide checkbox
            isHighlighted: false, // remove green bg
            withPhotos: "OK",
          },
        }
      }
      return resultItem
    })
    // update resultData with updatedResultData
    setResultData(updatedResultData)
    //uncheck all checkboxes
    setCheckedIdList([])
    setCheckableList(newcheckableList)
  }

  const handleChangeVisible = (e: React.ChangeEvent<HTMLInputElement>) => {
     const { id } = e.target
      setUpdateFrameId(+id)
      setShowConfirmModal(true)
  }

  //build array of products
  const handleResultDataUpdate = (data: any) => {
    if(!totalFrames)
      setTotalFrames(data?.getNewDOFrameCustomListing?.totalFrames)
      if (data) {
        const newDataTmp = [...resultData]
        data.getNewDOFrameCustomListing.edges.forEach(
          ({ node: originalNode }: { node: any }, index: number) => {
            const item = { ...originalNode }
            item.node = {}
            item.node.id = item.id
            item.node.ean = item.gtin
            item.node.isNew = Math.round((Date.now()-(item.creationDate*1000))/ (1000 * 60 * 60 * 24))<= +authCtx.websiteSettings.delaiNouveaute?'Oui':'Non'
            item.node.designation = item.name
            item.node.importDate = getFormattedDate(item.creationDate * 1000)
            item.node.stockBac = item.stock? (item.stock[0]?.totalQuantity ? item.stock[0]?.totalQuantity : "A ajouter"): "BUG"
            item.node.location = item.stockLocation
            item.node.checkboxVisible = !item.imageUrls || item.imageUrls === '[{"url":"","order":1}]'
            item.node.withPhotos = item.node.checkboxVisible ? "À associer" : "Oui"
            if (item.node.checkboxVisible){
              const uniqueSet = new Set(checkableList.map(item => item.id))
              //list with unique values
              if (!uniqueSet.has(item.id)) {
                //set checkableList
                setCheckableList(prevList => [...prevList, item]);
                //set default checked checkbox 
                setCheckedIdList((prev)=>[...prev, item.gtin])
              }
            }
            item.node.visible = <input
              className="form-check-input"
              type="checkbox"
              id={item.id}
              name="row-checkbox"
              checked={true}
              onChange={handleChangeVisible}
            />
            //green row for product without image 
            item.node.isHighlighted =  item.node.checkboxVisible
            let indexDuplicate = newDataTmp.findIndex((el) => el.id === item.id)
            if (indexDuplicate>=0) {
              const hasNoImage = !item.imageUrls || item.imageUrls === '[{"url":"","order":1}]'
              if(hasNoImage)
                newDataTmp[indexDuplicate] = item
            } else {
              newDataTmp.push(item)
            }
          }
        )
        setResultData(newDataTmp)
    }
  }

  //mutation to create and associate images with VTO
  const handleAssociateImg = async () => {
    setAssociationModalMessage("En cours de traitement, veuillez patienter. Cela peut prendre quelques minutes.")
    setShowAssociationModal(true)
    await associateVtoImgMutation({
        variables: {
          gtins: checkedIdList,
        },
    })
  }
  const loadMoreFrames = useCallback(() => {
    //if not loading and all the results are not yet displayed : resultData.length < totalFrames
    if(!loading && resultData.length < totalFrames){
      //change offset
      setOffset((prevOffset) => prevOffset + limit)
      // fetchMore
      fetchMore({
        variables: {
          offset: resultData.length,
          limit,
        },
      })
    }
  },[fetchMore, loading, resultData, totalFrames]) 

  //lazy load when scrolling
  useEffect(()=>{
    window.addEventListener('scrollend', loadMoreFrames)
    return () => {
      window.removeEventListener('scrollend', loadMoreFrames)
    }
  },[loadMoreFrames])

  const changeFrameVisibility = async() => {
    await updateFrameVisibility({
        variables: {
          id: updateFrameId,
          visible: false,
        },
    })
  }

  if (error) return <p>`Error! ${error.message}`</p>

  return (
    <div data-cy="monitoringProduitsResult" className="bg-light">
      <i className='text-muted p-2'>Nombre de produits {resultData.length}/{totalFrames}</i>
        {resultData && <ResultsTable
          items={resultData}
          queryType="Monitoring nouveaux produits"
          columns={stringsToJSXs(
            [
              // 'id',
              'ean',
              'designation',
              'isNew',
              'importDate',
              'stockBac',
              'location',
              'withPhotos',
              'visible'
            ],
            t
          )}
          tableHasCheckboxSelectAll={true}
          checkedIdListState={{ checkedIdList, setCheckedIdList }}
          checkboxColIndex={0}
          checkboxId="ean"
          checkableList={checkableList}
        ></ResultsTable>}
        {loading && <SkeletonBase count={20} />}
      {/* confirmation modal to make frame invisible */}
      <Modal show={showConfirmModal} onHide={()=>setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <div>Êtes-vous sûr de vouloir rendre non visible ce produit ?</div>
        </Modal.Header>
        <Modal.Body>Cette action est irréversible et le produit ne pourra plus être mis en ligne.</Modal.Body>
        <Modal.Footer>
          {updateFrameMutationLoading ? 
          <Spinner
            variant="primary"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          :<>
            <Button
              variant="primary"
              onClick={changeFrameVisibility}
            >
              Oui
            </Button>
            <Button
              variant="secondary"
              onClick={()=>setShowConfirmModal(false)}
            >
              Annuler
            </Button>
          </>} 
        </Modal.Footer>
      </Modal>

      {/* association images modal */}
      <Modal size={"lg"} show={showAssociationModal} onHide={()=>setShowAssociationModal(false)}>
        <Modal.Header closeButton={!associateVtoImgLoading}>
          Association des photos
        </Modal.Header>
        <Modal.Body>{associationModalMessage}</Modal.Body>
        <Modal.Footer>
          {associateVtoImgLoading ? 
          <Spinner
            variant="primary"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          :<Button
              variant="secondary"
              disabled={associateVtoImgLoading}
              onClick={()=>setShowAssociationModal(false)}
            >
              Ok
            </Button>} 
        </Modal.Footer>
      </Modal>

      {/* fixed Button */}
      <div className="position-fixed p-2 end-0 bottom-0 me-5 mb-5">
        <Button disabled={associateVtoImgLoading||loading||resultData.length===totalFrames}
           variant="primary shadow zindex-2 btn-radius"
           onClick={loadMoreFrames}
        >{loading? <Spinner variant="light" as="span" animation="border" size="sm"/> 
          : <PlusIcon className="header-title-icon"/>}
        </Button>
        <Button disabled={checkedIdList.length === 0 || associateVtoImgLoading}
           variant="primary shadow zindex-2 btn-radius"
           onClick={handleAssociateImg}
        >{associateVtoImgLoading? <Spinner variant="light" as="span" animation="border" size="sm" /> 
          : "Associer les photos"}
        </Button>
      </div>
    </div>
  )
}

export default MonitoringProduits